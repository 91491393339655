import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const LoginComponent = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { login } = useContext(AuthContext);
    const navigate = useHistory();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await login(username, password);
            navigate.push('/satis'); // Redirect to /satis after successful login
        } catch (error) {
            console.error('Failed to login:', error);
        }
    };

    const formStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '50vh'
    };

    const inputStyle = {
        margin: '10px 0',
        padding: '10px',
        width: '200px'
    };

    const buttonStyle = {
        marginTop: '20px',
        padding: '10px 20px'
    };

    return (
        <form onSubmit={handleSubmit} style={formStyle}>
            <div>
            <th></th><th></th><th></th><th></th><th></th><th></th>
                <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Kullanıcı Adı</label>
                <th></th><th></th><th></th><th></th><th></th><th></th>
                <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    style={inputStyle}
                />
            </div>
            <div>
                <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Şifre</label>
                <th></th><th></th><th></th><th></th><th></th><th></th>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    style={inputStyle}
                />
            </div>
            <button className="btn btn-primary" type="submit" style={buttonStyle}>Giriş Yap</button>

        </form>
    );
};

export default LoginComponent;
