import React, { Component } from 'react';
import ProductService from '../../services/ProductService';
import axios from 'axios';
import InputMask from 'react-input-mask'; // Import the react-input-mask library

class EditBuyHistoryComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            productId: this.props.match.params.productId,
            buyHistoryId: this.props.match.params.buyHistoryId,
            saticiFirma: '',
            faturaTarihi: '',
            faturaNo: '',
            urunAdedi: '',
            birimFiyatEU: '',
            birimFiyatTR: '',
            toplamFiyat: '',
            exchangeRate: null,
            exchangeRateError: ''
        };

        this.changeHandler = this.changeHandler.bind(this);
        this.updateBuyHistory = this.updateBuyHistory.bind(this);
        this.getExchangeRate = this.getExchangeRate.bind(this);
    }

    componentDidMount() {
        ProductService.getProductById(this.state.productId).then(res => {
            const product = res.data;
            const buyHistory = product.buyHistory.find(bh => bh.id === this.state.buyHistoryId);
            if (buyHistory) {
                this.setState({
                    saticiFirma: buyHistory.saticiFirma,
                    faturaTarihi: buyHistory.faturaTarihi,
                    faturaNo: buyHistory.faturaNo,
                    urunAdedi: buyHistory.urunAdedi,
                    birimFiyatEU: buyHistory.birimFiyatEU,
                    birimFiyatTR: buyHistory.birimFiyatTR,
                    toplamFiyat: buyHistory.toplamFiyat
                });
            }
        });
    }

    changeHandler(event) {
        const { name, value } = event.target;
        this.setState({ [name]: value }, () => {
            if (name === 'birimFiyatTR' || name === 'exchangeRate') {
                this.updateBirimFiyatEU();
            }
            if (name === 'urunAdedi' || name === 'birimFiyatEU') {
                this.updateToplamFiyat();
            }
        });
    }

    updateBirimFiyatEU() {
        const { birimFiyatTR, exchangeRate } = this.state;
        const birimFiyatTRFloat = parseFloat(birimFiyatTR);
        const exchangeRateFloat = parseFloat(exchangeRate);

        if (!isNaN(birimFiyatTRFloat) && !isNaN(exchangeRateFloat)) {
            const birimFiyatEU = (birimFiyatTRFloat / exchangeRateFloat).toFixed(2);
            this.setState({ birimFiyatEU });
        } else {
            this.setState({ birimFiyatEU: '' });
        }
    }

    updateToplamFiyat() {
        const { urunAdedi, birimFiyatEU } = this.state;
        const urunAdediFloat = parseFloat(urunAdedi);
        const birimFiyatEUFloat = parseFloat(birimFiyatEU);

        if (!isNaN(urunAdediFloat) && !isNaN(birimFiyatEUFloat)) {
            const toplamFiyat = urunAdediFloat * birimFiyatEUFloat;
            this.setState({ toplamFiyat: toplamFiyat.toFixed(2) });
        } else {
            this.setState({ toplamFiyat: '' });
        }
    }

    getExchangeRate() {
        const { faturaTarihi } = this.state;

        if (!faturaTarihi) {
            this.setState({ exchangeRateError: 'Lütfen geçerli bir tarih seçiniz.' });
            return;
        }

        const formattedDate = faturaTarihi.split('.').reverse().join('-'); 

        //https://manage.exchangeratesapi.io/dashboard?logged_in=1
        axios.get(`https://api.exchangeratesapi.io/v1/${formattedDate}?access_key=ae1831a74500e1bd49a41dbc5af4b349&symbols=TRY`)
            .then(response => {
                const rate = response.data.rates.TRY;
                this.setState({ exchangeRate: rate, exchangeRateError: '' }, () => {
                    this.updateBirimFiyatEU();
                });
            })
            .catch(error => {
                console.error('Error fetching exchange rate:', error);
                this.setState({ exchangeRateError: 'Geçerli bir tarih giriniz.' });
            });
    }

    updateBuyHistory(e) {
        e.preventDefault();
        const { faturaTarihi, saticiFirma, faturaNo, urunAdedi, birimFiyatEU, birimFiyatTR, toplamFiyat } = this.state;

        const buyHistory = {
            saticiFirma,
            faturaTarihi,
            faturaNo,
            urunAdedi,
            birimFiyatEU,
            birimFiyatTR,
            toplamFiyat
        };

        ProductService.updateBuyHistory(this.state.productId, this.state.buyHistoryId, buyHistory).then(res => {
            this.props.history.push(`/view-product/${this.state.productId}`);
        }).catch(err => {
            console.error('Error updating buy history:', err);
        });
    }

    cancel() {
        this.props.history.push(`/view-product/${this.state.productId}`);
    }

    render() {
        return (
            <div>
                <br />
                <div className="container">
                    <div className="row">
                        <div className="card col-md-6 offset-md-3 offset-md-3">
                            <h3 className="text-center">Satın Alma Kaydı Düzenle</h3>
                            <div className="card-body">
                                <form>
                                    <div className="form-group">
                                        <label>Satıcı Firma:</label>
                                        <input
                                            type="text"
                                            placeholder="Satıcı Firma"
                                            name="saticiFirma"
                                            className="form-control"
                                            value={this.state.saticiFirma}
                                            onChange={this.changeHandler}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Fatura No:</label>
                                        <input
                                            type="text"
                                            placeholder="Fatura No"
                                            name="faturaNo"
                                            className="form-control"
                                            value={this.state.faturaNo}
                                            onChange={this.changeHandler}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Birim Fiyat (₺):</label>
                                        <input
                                            type="text"
                                            placeholder="Birim Fiyat (₺)"
                                            name="birimFiyatTR"
                                            className="form-control"
                                            value={this.state.birimFiyatTR}
                                            onChange={this.changeHandler}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Fatura Tarihi:</label>
                                        {/* Using InputMask for date input */}
                                        <InputMask
                                            mask="99.99.9999"
                                            placeholder="Fatura Tarihi (dd.mm.yyyy)"
                                            name="faturaTarihi"
                                            className="form-control"
                                            value={this.state.faturaTarihi}
                                            onChange={this.changeHandler}
                                        />
                                        <button type="button" className="btn btn-primary mt-2" onClick={this.getExchangeRate}>Tarihsel Kur Hesapla</button>
                                        {this.state.exchangeRate && (
                                            <div className="mt-2">
                                                <strong>EUR/TRY Kur:</strong> {this.state.exchangeRate} ₺
                                            </div>
                                        )}
                                        {this.state.exchangeRateError && (
                                            <div className="mt-2 text-danger">
                                                {this.state.exchangeRateError}
                                            </div>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <label>Ürün Adedi:</label>
                                        <input
                                            type="text"
                                            placeholder="Ürün Adedi"
                                            name="urunAdedi"
                                            className="form-control"
                                            value={this.state.urunAdedi}
                                            onChange={this.changeHandler}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Birim Fiyat (€):</label>
                                        <input
                                            type="text"
                                            placeholder="Birim Fiyat (€)"
                                            name="birimFiyatEU"
                                            className="form-control"
                                            value={this.state.birimFiyatEU}
                                            readOnly
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Toplam Fiyat (€):</label>
                                        <input
                                            type="text"
                                            placeholder="Toplam Fiyat"
                                            name="toplamFiyat"
                                            className="form-control"
                                            value={this.state.toplamFiyat}
                                            readOnly
                                        />
                                    </div>
                                    <div style={{ textAlign: "center" }}>
                                        <button className="btn btn-success" onClick={this.updateBuyHistory}>Kaydet</button>
                                        <button className="btn btn-danger" onClick={this.cancel.bind(this)} style={{ marginLeft: '10px' }}>İptal</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default EditBuyHistoryComponent;
