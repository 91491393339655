import React, { Component } from 'react';
import InvoiceService from '../../services/InvoiceService';

class CreateInvoiceComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: this.props.match.params.id || '', // Get ID from URL if available
            no: '',
            firma: '',
            birim: '',
            ilgili: '',
            konu: '',
            tarih: '',
            discount: 0,
            items: [{ itemNumber: '', name: '', quantity: '', price: '', unit: '' }]
        };

        this.changeHandler = this.changeHandler.bind(this);
        this.handleItemChange = this.handleItemChange.bind(this);
        this.addItem = this.addItem.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.saveOrUpdateInvoice = this.saveOrUpdateInvoice.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    componentDidMount() {
        if (this.state.id) {
            InvoiceService.getInvoiceById(this.state.id).then((res) => {
                const invoice = res.data;
                this.setState({
                    no: invoice.no,
                    firma: invoice.firma,
                    birim: invoice.birim,
                    ilgili: invoice.ilgili,
                    konu: invoice.konu,
                    tarih: invoice.tarih,
                    discount: invoice.discount,
                    items: invoice.items || [{ itemNumber: '', name: '', quantity: '', price: '', unit: '' }]
                });
            }).catch((error) => {
                console.error("There was an error fetching the invoice!", error);
            });
        }
    }

    saveOrUpdateInvoice = (e) => {
        e.preventDefault();
        const invoiceData = {
            no: this.state.no,
            firma: this.state.firma,
            birim: this.state.birim,
            ilgili: this.state.ilgili,
            konu: this.state.konu,
            tarih: this.state.tarih,
            discount: this.state.discount,
            items: this.state.items
        };

        if (this.state.id) {
            // Update (PATCH) the existing invoice
            InvoiceService.updateInvoice(this.state.id, invoiceData).then((res) => {
                console.log("Invoice updated successfully:", res.data);
                this.props.history.push('/invoice'); // Redirect to invoice list
            }).catch((error) => {
                console.error("There was an error updating the invoice!", error);
            });
        } else {
            // Create a new invoice
            InvoiceService.createInvoice(invoiceData).then((res) => {
                console.log("Invoice created successfully:", res.data);
                this.props.history.push('/invoice'); // Redirect to invoice list
            }).catch((error) => {
                console.error("There was an error creating the invoice!", error);
            });
        }
    };

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleItemChange = (e, index) => {
        const { name, value } = e.target;
        const items = [...this.state.items];
        items[index][name] = value;
        this.setState({ items });
    };

    addItem() {
        this.setState({
            items: [...this.state.items, { itemNumber: '', name: '', quantity: '', price: '', unit: '' }]
        });
    }

    removeItem(index) {
        const items = [...this.state.items];
        items.splice(index, 1);
        this.setState({ items });
    }

    cancel() {
        this.props.history.push('/invoice'); // Redirect to invoice list
    }

    getTitle() {
        return this.state.id ? <h3 className="text-center">Teklif Güncelle</h3> : <h3 className="text-center">Teklif Ekle</h3>;
    }

    render() {
        return (
<div style={{ marginBottom: "50px", maxWidth: "500%" }}>
    <br />
    <div className="container" style={{ maxWidth: "100%" }}>
        <div className="row">
            <div className="card col-md-12 offset-md-0">
            <br />{this.getTitle()}
                <div className="card-body">
                    <form>
                        <div className="form-group">
                            <label> No: </label>
                            <input
                                type="text"
                                placeholder="Fatura No Giriniz"
                                name="no"
                                className="form-control"
                                value={this.state.no}
                                onChange={this.changeHandler}
                            />
                        </div>
                        <div className="form-group">
                            <label> Firma: </label>
                            <input
                                type="text"
                                placeholder="Firma Giriniz"
                                name="firma"
                                className="form-control"
                                value={this.state.firma}
                                onChange={this.changeHandler}
                            />
                        </div>
                        <div className="form-group">
                            <label> Birim: </label>
                            <input
                                type="text"
                                placeholder="Birim Giriniz"
                                name="birim"
                                className="form-control"
                                value={this.state.birim}
                                onChange={this.changeHandler}
                            />
                        </div>
                        <div className="form-group">
                            <label> İlgili: </label>
                            <input
                                type="text"
                                placeholder="İlgili Giriniz"
                                name="ilgili"
                                className="form-control"
                                value={this.state.ilgili}
                                onChange={this.changeHandler}
                            />
                        </div>
                        <div className="form-group">
                            <label> Konu: </label>
                            <input
                                type="text"
                                placeholder="Konu Giriniz"
                                name="konu"
                                className="form-control"
                                value={this.state.konu}
                                onChange={this.changeHandler}
                            />
                        </div>
                        <div className="form-group">
                            <label> Tarih: </label>
                            <input
                                type="date"
                                name="tarih"
                                className="form-control"
                                value={this.state.tarih}
                                onChange={this.changeHandler}
                            />
                        </div>
                        <div className="form-group">
                            <label> İskonto: </label>
                            <input
                                type="number"
                                name="discount"
                                className="form-control"
                                value={this.state.discount}
                                onChange={this.changeHandler}
                            />
                        </div>
                        <br />
                        <h3 style={{textAlign: 'center'}}>Satış</h3>
                        <br />
                        {this.state.items.map((item, index) => (
                            <div key={index} className="form-group row">
                                <label className="col-sm-1 col-form-label">İçerik: {index + 1}</label>
                                <div className="col-sm-2">
                                    <input
                                        type="text"
                                        placeholder="Barkod No"
                                        name="itemNumber"
                                        className="form-control"
                                        value={item.itemNumber}
                                        onChange={(e) => this.handleItemChange(e, index)}
                                    />
                                </div>
                                <div className="col-sm-2">
                                    <input
                                        type="text"
                                        placeholder="İşlem"
                                        name="name"
                                        className="form-control"
                                        value={item.name}
                                        onChange={(e) => this.handleItemChange(e, index)}
                                    />
                                </div>
                                <div className="col-sm-2" >
                                    <input
                                        type="number"
                                        placeholder="Miktar"
                                        name="quantity"
                                        className="form-control"
                                        value={item.quantity}
                                        onChange={(e) => this.handleItemChange(e, index)}
                                    />
                                </div>
                                <div className="col-sm-2">
                                    <input
                                        type="number"
                                        placeholder="Birim Fiyat"
                                        name="price"
                                        className="form-control"
                                        value={item.price}
                                        onChange={(e) => this.handleItemChange(e, index)}
                                    />
                                </div>
                                <div className="col-sm-2">
                                    <input style={{width:"120px"}}
                                        type="text"
                                        placeholder="Para Birimi"
                                        name="unit"
                                        className="form-control"
                                        value={item.unit}
                                        onChange={(e) => this.handleItemChange(e, index)}
                                    />
                                </div>
                                <div className="col-sm-1">
                                    <button
                                        type="button"
                                        className="btn btn-danger"
                                        onClick={() => this.removeItem(index)}
                                    >
                                        Sil
                                    </button>
                                </div>
                            </div>
                        ))}
                        <button type="button" className="btn btn-info" onClick={this.addItem}>
                            Satış Ekle
                        </button>
                        <button
                            className="btn btn-success"
                            onClick={this.saveOrUpdateInvoice}
                            style={{ marginLeft: "330px" }}
                        >
                            {this.state.id ? "Güncelle" : "Kaydet"} {/* Button text changes based on whether you're updating or creating */}
                        </button>
                        <button className="btn btn-danger" onClick={this.cancel} style={{ marginLeft: "10px" }}>
                            İptal
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
        );
    }
}

export default CreateInvoiceComponent;

