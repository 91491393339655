import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';  
import InvoiceService from '../../services/InvoiceService';

const ListInvoiceComponent = () => {
    const [invoices, setInvoices] = useState([]);
    const [filteredInvoices, setFilteredInvoices] = useState([]);
    const [searchFields, setSearchFields] = useState({
        no: "",
        firma: "",
        tarih: "",
        konu: ""
    });
    const history = useHistory();

    useEffect(() => {
        InvoiceService.getInvoices().then((response) => {
            setInvoices(response.data);
            setFilteredInvoices(response.data); // Initially, all invoices are shown
        }).catch(error => {
            console.error("There was an error fetching the invoices!", error);
        });
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSearchFields({ ...searchFields, [name]: value });
    };

    const searchInvoices = () => {
        let filteredData = invoices.filter((invoice) => {
            return (
                invoice.no.toLowerCase().includes(searchFields.no.toLowerCase()) &&
                invoice.firma.toLowerCase().includes(searchFields.firma.toLowerCase()) &&
                invoice.tarih.toLowerCase().includes(searchFields.tarih.toLowerCase()) &&
                invoice.konu.toLowerCase().includes(searchFields.konu.toLowerCase())
            );
        });
        setFilteredInvoices(filteredData);
    };

    const resetSearch = () => {
        setSearchFields({
            no: "",
            firma: "",
            tarih: "",
            konu: ""
        });
        setFilteredInvoices(invoices); // Reset to show all invoices
    };

    const redirectToUpdatePage = (id) => {
        history.push(`/update-invoice/${id}`); // Redirect to the update-invoice page with the invoice id
    };


    const downloadInvoice = (id) => {
        InvoiceService.downloadInvoice(id)
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'teklif.xlsx');
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch(error => {
                console.error("There was an error downloading the invoice!", error);
            });
    };

    const downloadPdf = (id) => {
        InvoiceService.convertExcelToPdf(id)
            .then(() => {
                console.log("PDF downloaded successfully.");
            })
            .catch(error => {
                console.error("There was an error downloading the PDF!", error);
            });
    };

    const deleteInvoice = (id) => {
        InvoiceService.deleteInvoice(id).then(() => {
            InvoiceService.getInvoices().then((response) => {
                setInvoices(response.data);
                setFilteredInvoices(response.data);
            });
        }).catch(error => {
            console.error("There was an error deleting the invoice!", error);
        });
    };

    const redirectToCreatePage = () => {
        history.push('/create-invoice');
    };

    return (
        <div>
            <br/><br/>
            <h2 className="text-center">Teklif Listesi</h2>
            <br/><br/>
            <div className="row" style={{marginLeft:"6px"}}>
                <button className="btn btn-primary" onClick={redirectToCreatePage}>Teklif Ekle</button>
                <button className="btn btn-secondary" onClick={resetSearch} style={{ marginLeft: "6px" }}>Aramayı Temizle</button>
                <input
                    style={{  marginLeft: "6px" }}
                    type="text"
                    name="no"
                    placeholder="Teklif No Ara"
                    value={searchFields.no}
                    onChange={handleInputChange}
                />
                <input
                    style={{  marginLeft: "6px" }}
                    type="text"
                    name="firma"
                    placeholder="Firma Ara"
                    value={searchFields.firma}
                    onChange={handleInputChange}
                />
                <input
                    style={{ marginLeft: "6px" }}
                    type="text"
                    name="tarih"
                    placeholder="Teklif Tarihi Ara"
                    value={searchFields.tarih}
                    onChange={handleInputChange}
                />
                <input
                    style={{  marginLeft: "6px" }}
                    type="text"
                    name="konu"
                    placeholder="Fatura Konusu Ara"
                    value={searchFields.konu}
                    onChange={handleInputChange}
                />
                <button className="btn btn-primary" onClick={searchInvoices} style={{ marginLeft: "6px" }}>Ara</button>
            </div>
            <br/>
            <table className="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th>Teklif No</th>
                        <th>Firma Adı</th>
                        <th>Teklif Tarihi</th>
                        <th>Fatura Konusu</th>
                        <th style={{ textAlign: 'center' }}>Eylemler</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredInvoices.map(invoice => (
                        <tr key={invoice._id}>
                            <td style={{ wordWrap: 'break-word', overflowWrap: 'break-word', maxWidth: '100px' }}>{invoice.no}</td>
                            <td style={{ wordWrap: 'break-word', overflowWrap: 'break-word', maxWidth: '100px' }}>{invoice.firma}</td>
                            <td style={{ wordWrap: 'break-word', overflowWrap: 'break-word', maxWidth: '100px' }}>{invoice.tarih}</td>
                            <td style={{ wordWrap: 'break-word', overflowWrap: 'break-word', maxWidth: '100px' }}>{invoice.konu}</td>
                            <td style={{ textAlign: 'center' }}>
                                <button onClick={() => downloadPdf(invoice.id)} className="btn btn-info" style={{ marginLeft: "10px" }}>PDF İndir</button>
                                <button onClick={() => downloadInvoice(invoice.id)} className="btn btn-info" style={{ marginLeft: "10px" }}>Excel İndir</button>
                                <button onClick={() => redirectToUpdatePage(invoice.id)} className="btn btn-warning" style={{ marginLeft: "10px" }}>Güncelle</button>
                                <button onClick={() => deleteInvoice(invoice.id)} className="btn btn-danger" style={{ marginLeft: "10px" }}>Sil</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ListInvoiceComponent;
