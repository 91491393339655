import React, { Component } from 'react'
import'bootstrap/dist/css/bootstrap.min.css';

class HeaderComponent extends Component {
    constructor(props) {
        super(props)



        this.state = {
                 
        }
    }

    render() {
        return (
            <div>
                <header>
                    <nav className="navbar navbar-dark bg-primary justify-content-center">
                    <img src="/akteklogo.jpeg" alt="Aktek Logo" style={{ width: '500px', height: 'auto' }}/>          
                    </nav>          
                    <nav className="navbar navbar-dark bg-primary">
                    <div><a href="/invoice" className="navbar-brand"></a></div>
                    <div><a href="/satis" className="navbar-brand">MOTOR TAKİP</a></div>
                    <div><a href="/invoice" className="navbar-brand"></a></div>
                    <div><a href="/stok" className="navbar-brand">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SATIN ALMA</a></div>
                    <div><a href="/invoice" className="navbar-brand"></a></div>
                    <div><a href="/invoice" className="navbar-brand">TEKLİF HAZIRLAMA</a></div>
                    <div><a href="/invoice" className="navbar-brand"></a></div>
                    </nav>
                </header>
            </div>
        )
    }
}

export default HeaderComponent
