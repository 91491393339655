import React, { useContext, useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import FooterComponent from './components/FooterComponent';
import HeaderComponent from './components/HeaderComponent';

import ListUserComponent from './components/user/ListUserComponent';
import CreateUserComponent from './components/user/CreateUserComponent';
import ViewUserComponent from './components/user/ViewUserComponent';

import ListProductComponent from './components/products/ListProductComponent';
import CreateProductComponent from './components/products/CreateProductComponent';
import ViewProductComponent from './components/products/ViewProductComponent';
import CreateBuyHistoryComponent from './components/products/CreateBuyHistoryComponent';
import EditBuyHistoryComponent from './components/products/EditBuyHistoryComponent';

import ListInvoiceComponent from "./components/invoice/ListInvoiceComponent";
import CreateInvoiceComponent from "./components/invoice/CreateInvoiceComponent";
import DownloadInvoiceComponent from "./components/invoice/DownloadInvoiceComponent";

import Satis from './components/satis/satis';
import Stok from './components/stok/stok';
import Calisanlar from './components/calisanlar/calisanlar';
import Invoice from './components/fatura/invoice';
import Landing from './components/landing/landing';

import LoginComponent from './components/LoginComponent';
import RegisterComponent from './components/RegisterComponent';
import { AuthContext, AuthProvider } from './context/AuthContext';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { user } = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={props =>
        user ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

const App = () => {
  const { user } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    if (user) {
      setIsAuthenticated(true);
    }
    setIsLoading(false);
  }, [user]);

  if (isLoading) {
    return <div>Loading...</div>; // Show a loading state while checking authentication
  }

  return (
    <Router>
      <HeaderComponent />
      <div className="container">
        <Switch>
          <Route path="/login" component={LoginComponent} />
          <PrivateRoute path="/register" component={RegisterComponent} />
          <PrivateRoute path="/landing" component={Landing} />
          <PrivateRoute path="/calisanlar" component={Calisanlar} />
          <PrivateRoute path="/invoice" component={Invoice} />
          <PrivateRoute path="/satis" component={Satis} />
          <PrivateRoute path="/stok" component={Stok} />
          <PrivateRoute path="/" exact component={ListUserComponent} />
          <PrivateRoute path="/users" component={ListUserComponent} />
          <PrivateRoute path="/add-user/:id" component={CreateUserComponent} />
          <PrivateRoute path="/view-user/:id" component={ViewUserComponent} />
          <PrivateRoute path="/products" exact component={ListProductComponent} />
          <PrivateRoute path="/add-product/:id" component={CreateProductComponent} />
          <PrivateRoute path="/view-product/:id" component={ViewProductComponent} />
          <PrivateRoute path="/add-buy-history/:id" component={CreateBuyHistoryComponent} />
          <PrivateRoute path="/edit-buy-history/:productId/:buyHistoryId" component={EditBuyHistoryComponent} />
          <PrivateRoute path="/invoice" component={ListInvoiceComponent} />
          <PrivateRoute path="/create-invoice/" component={CreateInvoiceComponent} />
          <PrivateRoute path="/download-invoice/:id" component={DownloadInvoiceComponent} />
          <PrivateRoute path="/update-invoice/:id" component={CreateInvoiceComponent} />

          <Redirect from="/" to="/login" />
        </Switch>
      </div>
      <FooterComponent />
    </Router>
  );
};

const AppWrapper = () => (
  <AuthProvider>
    <App />
  </AuthProvider>
);

export default AppWrapper;
