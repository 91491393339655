import axios from 'axios';

const INVOICE_API_BASE_URL = "http://35.209.147.187:8051"; // Your backend URL
const CLOUDCONVERT_API_KEY = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiY2U0ZGI1MjUxODVlZDAyZWQ1YWI3NjBhNmMyOTlhNjRlZjZkN2IzNDViZWE5ZDVkMGM5NzZmMzlkZDQzNWQwYzEzZDgzMGIxZWNiMTA4NzQiLCJpYXQiOjE3Mjc4NTI2NjQuMDUxMDkzLCJuYmYiOjE3Mjc4NTI2NjQuMDUxMDk0LCJleHAiOjQ4ODM1MjYyNjQuMDQ1NzQ5LCJzdWIiOiI2OTc2MzkxOSIsInNjb3BlcyI6WyJ1c2VyLnJlYWQiLCJ1c2VyLndyaXRlIiwidGFzay5yZWFkIiwidGFzay53cml0ZSIsIndlYmhvb2sucmVhZCIsIndlYmhvb2sud3JpdGUiLCJwcmVzZXQucmVhZCIsInByZXNldC53cml0ZSJdfQ.HqY3sELNf-_gwUC5QDO29OEtlHZ4lxjh2I9kFFNaapjXyFh9oPZz4QuFa1H4ru5rseWzO4zj3nPtu-3vrJ-w9Y48JtJZEHSU0RvxbpGut_cNl6bUaVDJfaJBtvJC5D-U7H-eEdyDBvCUH_0SuknkS_xY_4Q58-s0pGVKibBWrq28LhSsOuL2fIw92ZzrE9AgPVmd3TghX3c_YUZSDeV4kdiwdMMkyGmT3RsELWuHna6sSMi3LcxB43gJ2H8qbR9_ZfoPAWzXseHJL9y0Rz873SFiSAmZTFlOpLqSjFcn0rcqFZhH-l5bjHfzoc8yecrSerFRRqxuuzlDPJvDIuKhcUohegYm6g0T2xF_syZ2m1aauo3K9ertyyYH1kpZawg0yuCr2a8j8UiBax6s0fodUHL-8Hcdz5uP4ihJUQ8Nct2wlXbkel9Hil0VJMo-Q_Dtt_-C_ZUmN4RkLHKMlNwG68yoBRgGTooGRM_ew4P9s1Z5ccnu7D71XpS7ZQfnE8AuYoxs9rj_XV9hk8PjPO5q-F_N0bGpcFLgC0Vdis30noJP0NUh7fuqWu40lawfIFPPU9z6ql9Y1K2aVrTfYTAhn4J3_bUK6a0K7UtiuaCjZmPdWT2j5BMvBujZyznwkgYzlIkfWmWnJHGR1dN0sT1FhXlk-OVRBRZQxVi1ID9gHUk'; //https://cloudconvert.com/settings/billing/plan

class InvoiceService {
  createInvoice(invoiceData) {
    return axios.post(`${INVOICE_API_BASE_URL}/edit-excel`, invoiceData);
  }

  getInvoices() {
    return axios.get(`${INVOICE_API_BASE_URL}/invoices`); // Assuming you have this endpoint
  }

  getInvoiceById(id) {
    return axios.get(`${INVOICE_API_BASE_URL}/invoices/${id}`); // Assuming you have this endpoint
  }

  updateInvoice(id, invoiceData) {
    return axios.patch(`${INVOICE_API_BASE_URL}/update-excel/${id}`, invoiceData);
}

  downloadInvoice(id) {
    return axios({
        url: `${INVOICE_API_BASE_URL}/download-excel/${id}`,
        method: 'GET',
        responseType: 'blob' // important to handle binary data
    });
  }

  convertExcelToPdf(id) {
    const encodedDownloadUrl = `${INVOICE_API_BASE_URL}/download-excel/${id}`;

    return axios.post('https://api.cloudconvert.com/v2/import/url', {
        url: encodedDownloadUrl,
        filename: "teklif_excel.xlsx"
    }, {
        headers: {
            'Authorization': `Bearer ${CLOUDCONVERT_API_KEY}`,
            'Content-Type': 'application/json'
        }
    }).then((response) => {
        const importTaskId = response.data.data.id;
        // Poll the import task to check for completion
        return this.pollTaskCompletion(importTaskId);
    }).then((importTaskData) => {
        // Once the import task is completed, start the conversion to PDF
        const importTaskId = importTaskData.id;
        return axios.post(`https://api.cloudconvert.com/v2/convert`, {
            input: importTaskId,
            input_format: "xlsx",
            output_format: "pdf"
        }, {
            headers: {
                'Authorization': `Bearer ${CLOUDCONVERT_API_KEY}`,
                'Content-Type': 'application/json'
            }
        });
    }).then((convertTaskResponse) => {
        const convertTaskId = convertTaskResponse.data.data.id;
        // Poll the convert task to check for completion
        return this.pollTaskCompletion(convertTaskId);
    }).then((convertTaskData) => {
        // Once the conversion is completed, export the PDF
        const convertTaskId = convertTaskData.id;
        return axios.post(`https://api.cloudconvert.com/v2/export/url`, {
            input: convertTaskId
        }, {
            headers: {
                'Authorization': `Bearer ${CLOUDCONVERT_API_KEY}`,
                'Content-Type': 'application/json'
            }
        });
    }).then((exportTaskResponse) => {
        const exportTaskId = exportTaskResponse.data.data.id;
        // Poll the export task to check for completion
        return this.pollTaskCompletion(exportTaskId);
    }).then((exportTaskData) => {
        // Finally, get the URL for the PDF file
        const downloadUrl = exportTaskData.result.files[0].url;
        return this.downloadFile(downloadUrl, "teklif.pdf");
    });
}

// Helper function to poll the task completion
pollTaskCompletion(taskId) {
    return new Promise((resolve, reject) => {
        const interval = setInterval(() => {
            axios.get(`https://api.cloudconvert.com/v2/tasks/${taskId}`, {
                headers: {
                    'Authorization': `Bearer ${CLOUDCONVERT_API_KEY}`
                }
            }).then((response) => {
                const taskData = response.data.data;
                if (taskData.status === "finished") {
                    clearInterval(interval);
                    resolve(taskData);
                } else if (taskData.status === "failed") {
                    clearInterval(interval);
                    reject(new Error("Task failed"));
                }
            }).catch((error) => {
                clearInterval(interval);
                reject(error);
            });
        }, 5000); // Poll every 5 seconds
    });
}

// Helper function to download a file from a URL
downloadFile(url, filename) {
    return axios.get(url, { responseType: 'blob' }).then((response) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
    });
}

  deleteInvoice(id) {
    return axios.delete(`${INVOICE_API_BASE_URL}/delete-excel/${id}`);
  }

}

export default new InvoiceService();
