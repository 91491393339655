import React, { Component } from 'react'
import UserService from '../../services/UserService';

class CreateUserComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            // step 2
            id: this.props.match.params.id,
            barkodNo: '',
            firmaAdi: '',
            girisTarihi: '',
            cikisTarihi: '',
            motorMarkasi: '',
            motorGucu: '',
            motorDevri: '',
            rulmanNo: '',
            yapilanIslem: '',
            faturaDurumu: '',
            notKismi: ''
        }
        this.changeFirmaAdiHandler = this.changeFirmaAdiHandler.bind(this);
        this.changeGirisTarihiHandler = this.changeGirisTarihiHandler.bind(this);
        this.saveOrUpdateUser = this.saveOrUpdateUser.bind(this);
    }

    // step 3
    componentDidMount() {

        // step 4
        if (this.state.id === '_add') {
            return
        } else {
            UserService.getUserById(this.state.id).then((res) => {
                let user = res.data;
                this.setState({
                    barkodNo: user.barkodNo,
                    firmaAdi: user.firmaAdi,
                    girisTarihi: user.girisTarihi,
                    cikisTarihi: user.cikisTarihi,
                    motorMarkasi: user.motorMarkasi,
                    motorGucu: user.motorGucu,
                    motorDevri: user.motorDevri,
                    rulmanNo: user.rulmanNo,
                    yapilanIslem: user.yapilanIslem,
                    faturaDurumu: user.faturaDurumu,
                    notKismi: user.notKismi,
                });
            });
        }
    }
    saveOrUpdateUser = (e) => {
        e.preventDefault();
        let user = { barkodNo: this.state.barkodNo, firmaAdi: this.state.firmaAdi, girisTarihi: this.state.girisTarihi, cikisTarihi: this.state.cikisTarihi, motorMarkasi: this.state.motorMarkasi, motorGucu: this.state.motorGucu, motorDevri: this.state.motorDevri, rulmanNo: this.state.rulmanNo, yapilanIslem: this.state.yapilanIslem, faturaDurumu: this.state.faturaDurumu , notKismi: this.state.notKismi };
        console.log('user => ' + JSON.stringify(user));

        // step 5
        if (this.state.id === '_add') {
            UserService.createUser(user).then(res => {
                this.props.history.push('/satis');
            });
        } else {
            UserService.updateUser(user, this.state.id).then(res => {
                this.props.history.push('/satis');
            });
        }
    }

    changeBarkodNoHandler = (event) => {
        this.setState({ barkodNo: event.target.value });
    }

    changeFirmaAdiHandler = (event) => {
        this.setState({ firmaAdi: event.target.value });
    }

    changeGirisTarihiHandler = (event) => {
        this.setState({ girisTarihi: event.target.value });
    }

    changeCikisTarihiHandler = (event) => {
        this.setState({ cikisTarihi: event.target.value });
    }

    changeMotorMarkasiHandler = (event) => {
        this.setState({ motorMarkasi: event.target.value });
    }

    changeMotorGucuHandler = (event) => {
        this.setState({ motorGucu: event.target.value });
    }

    changeMotorDevriHandler = (event) => {
        this.setState({ motorDevri: event.target.value });
    }

    changeRulmanNoHandler = (event) => {
        this.setState({ rulmanNo: event.target.value });
    }

    changeYapilanIslemHandler = (event) => {
        this.setState({ yapilanIslem: event.target.value });
    }

    changeFaturaDurumuHandler = (event) => {
        this.setState({ faturaDurumu: event.target.value });
    }

    changeNotKismiHandler = (event) => {
        this.setState({ notKismi: event.target.value });
    }

    cancel() {
        this.props.history.push('/satis');
    }

    getTitle() {
        if (this.state.id === '_add') {
            return <h3 className="text-center">Satış Ekle</h3>
        } else {
            return <h3 className="text-center">Satış Güncelle</h3>
        }
    }
    render() {
        return (
            <div  style={{ marginBottom: "50px" }}>
                <br></br>
                <div className="container">
                    <div className="row">
                        <div className="card col-md-6 offset-md-3 offset-md-3"><br />
                            {
                                
                                this.getTitle()
                            }
                            <div className="card-body">
                                <form>
                                    <div className="form-group">
                                        <label> Barkod No: </label>
                                        <input placeholder="" name="barkodNo" className="form-control"
                                            value={this.state.barkodNo} onChange={this.changeBarkodNoHandler} />
                                    </div>
                                    <div className="form-group">
                                        <label> Firma Adı: </label>
                                        <input placeholder="" name="firmaAdi" className="form-control"
                                            value={this.state.firmaAdi} onChange={this.changeFirmaAdiHandler} />
                                    </div>
                                    <div className="form-group">
                                        <label> Giriş Tarihi: </label>
                                        <input placeholder="" name="girisTarihi" className="form-control"
                                            value={this.state.girisTarihi} onChange={this.changeGirisTarihiHandler} />
                                    </div>
                                    <div className="form-group">
                                        <label> Çıkış Tarihi: </label>
                                        <input placeholder="" name="cikisTarihi" className="form-control"
                                            value={this.state.cikisTarihi} onChange={this.changeCikisTarihiHandler} />
                                    </div>
                                    <div className="form-group">
                                        <label> Motor Markası: </label>
                                        <input placeholder="" name="motorMarkasi" className="form-control"
                                            value={this.state.motorMarkasi} onChange={this.changeMotorMarkasiHandler} />
                                    </div>
                                    <div className="form-group">
                                        <label> Motor Gücü (W): </label>
                                        <input placeholder="" name="motorGucu" className="form-control"
                                            value={this.state.motorGucu} onChange={this.changeMotorGucuHandler} />
                                    </div>
                                    <div className="form-group">
                                        <label> Motor Devri: </label>
                                        <input placeholder="" name="motorDevri" className="form-control"
                                            value={this.state.motorDevri} onChange={this.changeMotorDevriHandler} />
                                    </div>
                                    <div className="form-group">
                                        <label> Rulman Numarası: </label>
                                        <input placeholder="" name="motorDevri" className="form-control"
                                            value={this.state.rulmanNo} onChange={this.changeRulmanNoHandler} />
                                    </div>
                                    <div className="form-group">
                                        <label> Yapılan İşlem: </label>
                                        <input placeholder="" name="yapilanIslem" className="form-control"
                                            value={this.state.yapilanIslem} onChange={this.changeYapilanIslemHandler} />
                                    </div>
                                    <div className="form-group">
                                        <label> Fatura Durumu: </label>
                                        <th></th>
                                        <label> <input type="checkbox" name="faturaDurumu" value="kesildi" checked={this.state.faturaDurumu === "kesildi"} onChange={this.changeFaturaDurumuHandler}/> Kesildi </label>
                                        <th></th>
                                        <label> <input type="checkbox" name="faturaDurumu" value="kesilmedi" checked={this.state.faturaDurumu === "kesilmedi"} onChange={this.changeFaturaDurumuHandler} /> Kesilmedi </label>
                                        <th></th>
                                        <label> <input type="checkbox" name="faturaDurumu" value="kesilebilir" checked={this.state.faturaDurumu === "kesilebilir"} onChange={this.changeFaturaDurumuHandler} /> Kesilebilir </label>
                                   </div>
                                    <div className="form-group">
                                        <label> Notlar: </label>
                                        <input placeholder="" name="notKismi" className="form-control"
                                            value={this.state.notKismi} onChange={this.changeNotKismiHandler} />
                                    </div>
                                    <div style={{ textAlign: "center" }}>
                                        <button className="btn btn-success" onClick={this.saveOrUpdateUser}>Kaydet</button>
                                        <button className="btn btn-danger" onClick={this.cancel.bind(this)} style={{ marginLeft: "10px" }}>İptal</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default CreateUserComponent
