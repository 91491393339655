import React from 'react';

const Calisanlar = () => {
    return (
        <div>
            <h1>Çalışan Listesi</h1>
            <h3>çalışanlar</h3>
        </div>
    );
};

export default Calisanlar;