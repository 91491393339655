import api from './api';

const USER_API_BASE_URL = "http://35.209.147.187:8071/api/v1/users";

const checkAuthAndRedirect = async () => {
    try {
        const response = await api.get('/auth/check');
        console.log('Auth check successful:', response.data);
    } catch (error) {
        console.log('Auth check failed:', error.response ? error.response.data : error.message);
        localStorage.removeItem('user');
        window.location.href = '/login'; 
        
    }
}

class UserService {
    async getUsers7(searchQuery7 = '') {
        await checkAuthAndRedirect();
        let url = USER_API_BASE_URL;
        if (searchQuery7) {
            url += `?barkodNo=${encodeURIComponent(searchQuery7)}`;
        }
        return api.get(url);
    }

    async getUsers(searchQuery = '') {
        await checkAuthAndRedirect();
        let url = USER_API_BASE_URL;
        if (searchQuery) {
            url += `?firmaAdi=${encodeURIComponent(searchQuery)}`;
        }
        return api.get(url);
    }

    async getUsers2(searchQuery2 = '') {
        await checkAuthAndRedirect();
        let url = USER_API_BASE_URL;
        if (searchQuery2) {
            url += `?girisTarihi=${encodeURIComponent(searchQuery2)}`;
        }
        return api.get(url);
    }

    async getUsers3(searchQuery3 = '') {
        await checkAuthAndRedirect();
        let url = USER_API_BASE_URL;
        if (searchQuery3) {
            url += `?cikisTarihi=${encodeURIComponent(searchQuery3)}`;
        }
        return api.get(url);
    }

    async getUsers11(searchQuery11 = '') {
        await checkAuthAndRedirect();
        let url = USER_API_BASE_URL;
        if (searchQuery11) {
            url += `?motorMarkasi=${encodeURIComponent(searchQuery11)}`;
        }
        return api.get(url);
    }

    async getUsers4(searchQuery4 = '') {
        await checkAuthAndRedirect();
        let url = USER_API_BASE_URL;
        if (searchQuery4) {
            url += `?motorGucu=${encodeURIComponent(searchQuery4)}`;
        }
        return api.get(url);
    }

    async getUsers5(searchQuery5 = '') {
        await checkAuthAndRedirect();
        let url = USER_API_BASE_URL;
        if (searchQuery5) {
            url += `?motorDevri=${encodeURIComponent(searchQuery5)}`;
        }
        return api.get(url);
    }

    async getUsers10(searchQuery10 = '') {
        await checkAuthAndRedirect();
        let url = USER_API_BASE_URL;
        if (searchQuery10) {
            url += `?rulmanNo=${encodeURIComponent(searchQuery10)}`;
        }
        return api.get(url);
    }

    async getUsers6(searchQuery6 = '') {
        await checkAuthAndRedirect();
        let url = USER_API_BASE_URL;
        if (searchQuery6) {
            url += `?yapilanIslem=${encodeURIComponent(searchQuery6)}`;
        }
        return api.get(url);
    }

    async getUsers8(searchQuery8 = '') {
        await checkAuthAndRedirect();
        let url = USER_API_BASE_URL;
        if (searchQuery8) {
            url += `?faturaDurumu=${encodeURIComponent(searchQuery8)}`;
        }
        return api.get(url);
    }

    async getUsers12(searchQuery12 = '') {
        await checkAuthAndRedirect();
        let url = USER_API_BASE_URL;
        if (searchQuery12) {
            url += `?notKismi=${encodeURIComponent(searchQuery12)}`;
        }
        return api.get(url);
    }

    async getUsers9(searchQuery9 = '') {
        await checkAuthAndRedirect();
        let url = USER_API_BASE_URL;
        if (searchQuery9) {
            return api.get(USER_API_BASE_URL);
        }
        return api.get(url);
    }

    async createUser(user) {
        await checkAuthAndRedirect();
        return api.post(USER_API_BASE_URL, user);
    }

    async getUserById(userId) {
        await checkAuthAndRedirect();
        return api.get(USER_API_BASE_URL + '/' + userId);
    }

    async updateUser(user, userId) {
        await checkAuthAndRedirect();
        return api.put(USER_API_BASE_URL + '/' + userId, user);
    }

    async deleteUser(userId) {
        await checkAuthAndRedirect();
        return api.delete(USER_API_BASE_URL + '/' + userId);
    }
}

export default new UserService();
