import React, { Component } from 'react'
import UserService from '../../services/UserService'

class ViewUserComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            user: {}
        }
    }

    componentDidMount(){
        UserService.getUserById(this.state.id).then( res => {
            this.setState({user: res.data});
        })
    }

    render() {
        return (
            <div>
                <br></br>
                <div className = "card col-md-6 offset-md-3">
                <br />
                    <h3 className = "text-center"> Motor Onarım Satış Detayları</h3>
                    <div className = "card-body">
                        <div className = "row">
                            <label> Barkod Numarası: </label>
                            <div> { this.state.user.barkodNo }</div>
                        </div>
                        <div className = "row">
                            <label> Firma Adı: </label>
                            <div> { this.state.user.firmaAdi }</div>
                        </div>
                        <div className = "row">
                            <label> Giriş Tarihi: </label>
                            <div> { this.state.user.girisTarihi }</div>
                        </div>
                        <div className = "row">
                            <label> Çıkış Tarihi: </label>
                            <div> { this.state.user.cikisTarihi }</div>
                        </div>
                        <div className = "row">
                            <label> Motor Markası: </label>
                            <div> { this.state.user.motorMarkası }</div>
                        </div>
                        <div className = "row">
                            <label> Motor Gücü (W): </label>
                            <div> { this.state.user.motorGucu }</div>
                        </div>
                        <div className = "row">
                            <label> Motor Devri: </label>
                            <div> { this.state.user.motorDevri }</div>
                        </div>
                        <div className = "row">
                            <label> Rulman Numarası: </label>
                            <div> { this.state.user.rulmanNo }</div>
                        </div>
                        <div className = "row">
                            <label> Yapılan İşlem: </label>
                            <div> { this.state.user.yapilanIslem }</div>
                        </div>
                        <div className = "row">
                            <label> Fatura Durumu: </label>
                            <div> { this.state.user.faturaDurumu }</div>
                        </div>
                        <div className = "row">
                            <label> Notlar: </label>
                            <div> { this.state.user.notKismi }</div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default ViewUserComponent
