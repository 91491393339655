import React from 'react';

import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'

import ListInvoiceComponent from '../invoice/ListInvoiceComponent';
import CreateInvoiceComponent from '../invoice/CreateInvoiceComponent';
import DownloadInvoiceComponent from '../invoice/DownloadInvoiceComponent';


const Invoice = () => {
    return (
        <div className="container">
        <Switch>
              <Route path = "/invoice" exact component = {ListInvoiceComponent}></Route>
              <Route path = "/invoices" component = {ListInvoiceComponent}></Route>
              <Route path = "/create-invoice/" component = {CreateInvoiceComponent}></Route>
              <Route path = "/download-invoice/:id" component = {DownloadInvoiceComponent}></Route>
        </Switch>
    </div>
    );
};

export default Invoice;