import axios from 'axios';

const PRODUCT_API_BASE_URL = "http://35.209.147.187:8091/api/v1/products";

class ProductService {

    getProducts(searchQuery = '') {
        let url = PRODUCT_API_BASE_URL;
        if (searchQuery) {
            url += `?malzemeAdi=${encodeURIComponent(searchQuery)}`;
        }
        return axios.get(url);
    }

    getProducts2(searchQuery2 = '') {
        let url = PRODUCT_API_BASE_URL;
        if (searchQuery2) {
            url += `?seriNumara=${encodeURIComponent(searchQuery2)}`;
        }
        return axios.get(url);
    }

    getProducts3(searchQuery3 = '') {
        let url = PRODUCT_API_BASE_URL;
        if (searchQuery3) {
            url += `?adetNo=${encodeURIComponent(searchQuery3)}`;
        }
        return axios.get(url);
    }

    getProducts4(searchQuery4 = '') {
        let url = PRODUCT_API_BASE_URL;
        if (searchQuery4) {
            return axios.get(PRODUCT_API_BASE_URL);
        }
        return axios.get(url);
    }

    createProduct(product){
        return axios.post(PRODUCT_API_BASE_URL, product);
    }

    getProductById(productId){
        return axios.get(PRODUCT_API_BASE_URL + '/' + productId);
    }

    updateProduct(product, productId){
        return axios.put(PRODUCT_API_BASE_URL + '/' + productId, product);
    }

    deleteProduct(productId){
        return axios.delete(PRODUCT_API_BASE_URL + '/' + productId);
    }

    addBuyHistory(productId, buyHistory) {
        return axios.post(`${PRODUCT_API_BASE_URL}/${productId}/buyHistory`, buyHistory);
    }

    deleteBuyHistory(productId, historyId) {
        return axios.delete(`${PRODUCT_API_BASE_URL}/${productId}/buyHistory/${historyId}`);
    }

    updateBuyHistory(productId, buyHistoryId, buyHistory) {
        return axios.patch(`${PRODUCT_API_BASE_URL}/${productId}/buyHistory/${buyHistoryId}`, buyHistory);
    }
}

export default new ProductService()