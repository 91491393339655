import React, { Component } from 'react';
import axios from 'axios';
import ProductService from '../../services/ProductService';

class ViewProductComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: this.props.match.params.id,
            product: {},
            buyHistoryStats: { max: null, min: null, avg: null, maxFirma: '', minFirma: '' },
            euroExchangeRate: null,
        };
    }

    componentDidMount() {
        this.fetchProduct();
        this.fetchExchangeRate();
    }

    fetchExchangeRate = async () => {
        try {
            const response = await axios.get('https://api.exchangerate-api.com/v4/latest/EUR');
            const euroToTry = response.data.rates.TRY;
            this.setState({ euroExchangeRate: euroToTry }, () => {
                this.calculateBuyHistoryStats();
            });
        } catch (error) {
            console.error('Error fetching exchange rate:', error);
        }
    }

    fetchProduct = () => {
        ProductService.getProductById(this.state.id).then((res) => {
            const product = res.data;
            this.setState({ product }, () => {
                this.calculateBuyHistoryStats();
            });
        });
    }

    isValidNumber = (value) => {
        return !isNaN(value) && value.trim() !== '';
    }

    calculateBuyHistoryStats = () => {
        const { product, euroExchangeRate } = this.state;
        if (product.buyHistory && product.buyHistory.length > 0 && euroExchangeRate) {
            const validBuyHistory = product.buyHistory.filter(item => this.isValidNumber(item.birimFiyatEU));

            if (validBuyHistory.length === 0) {
                return;
            }

            const birimFiyatEU = validBuyHistory.map(item => parseFloat(item.birimFiyatEU));
            const maxIndex = birimFiyatEU.indexOf(Math.max(...birimFiyatEU));
            const minIndex = birimFiyatEU.indexOf(Math.min(...birimFiyatEU));
            const maxFirma = validBuyHistory[maxIndex].saticiFirma;
            const minFirma = validBuyHistory[minIndex].saticiFirma;
            const maxTR = birimFiyatEU[maxIndex] * euroExchangeRate;
            const minTR = birimFiyatEU[minIndex] * euroExchangeRate;
            const avg = birimFiyatEU.reduce((a, b) => a + b, 0) / birimFiyatEU.length;
            const avgTR = avg * euroExchangeRate;
            this.setState({
                buyHistoryStats: { 
                    max: birimFiyatEU[maxIndex], 
                    min: birimFiyatEU[minIndex], 
                    avg: avg.toFixed(2), 
                    avgTR: avgTR.toFixed(2), 
                    maxFirma, 
                    minFirma, 
                    maxTR: maxTR.toFixed(2), 
                    minTR: minTR.toFixed(2) 
                }
            });
        }
    }

    deleteBuyHistory = (historyId) => {
        ProductService.deleteBuyHistory(this.state.id, historyId).then(() => {
            this.fetchProduct();
        });
    }

    editBuyHistory(productId, buyHistoryId) {
        this.props.history.push(`/edit-buy-history/${productId}/${buyHistoryId}`);
    }
    
    addBuyHistory = () => {
        this.props.history.push(`/add-buy-history/${this.state.id}`);
    }

    render() {
        const { product, buyHistoryStats, euroExchangeRate } = this.state;
        return (
            <div>
                <br></br>
                <div className="card">
                <br></br>
                        <h3 className="text-center"> Satın Alma Detayları</h3>
                    
                    <div className="card-body">
                        <br></br>
                        <div className="row mb-4 justify-content-center text-center">
                            <div className="col-md-2">
                                <h5>Malzeme Adı:</h5>
                            </div>
                            <div className="col-md-2">
                                <h5>{product.malzemeAdi}</h5>
                            </div>
                            <div className="col-md-2">
                                <h5>Adet: </h5>
                            </div>
                            <div className="col-md-2">
                                <h5>{product.adetNo}</h5>
                            </div>
                        </div>
                        <br></br>
                        <div className="row" style={{ display: 'flex', justifyContent: 'center', width: '103%' }}>
                            <h3 className="text-center">Özet İstatistikler</h3>
                            <br></br><br></br>
                            <table className="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th>Koşullar</th>
                                        <th>Firma</th>
                                        <th>Birim Fiyat (€)</th>
                                        <th>Birim Fiyat (₺)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Maksimum Fiyat</td>
                                        <td>{buyHistoryStats.maxFirma}</td>
                                        <td>{buyHistoryStats.max}</td>
                                        <td>{buyHistoryStats.maxTR}</td>
                                    </tr>
                                    <tr>
                                        <td>Minimum Fiyat</td>
                                        <td>{buyHistoryStats.minFirma}</td>
                                        <td>{buyHistoryStats.min}</td>
                                        <td>{buyHistoryStats.minTR}</td>
                                    </tr>
                                    <tr>
                                        <td>Ortalama Fiyat</td>
                                        <td></td>
                                        <td>{buyHistoryStats.avg}</td>
                                        <td>{buyHistoryStats.avgTR}</td>
                                    </tr>
                                </tbody>
                                
                                {euroExchangeRate && <h5>EUR/TRY KUR: {euroExchangeRate} ₺</h5>}
                            </table>
                            
                        </div>
                        <br></br>
                        <div className="row" style={{ display: 'flex', justifyContent: 'center', width: '105%' }}>
                            <br></br>
                            <h3 className="text-center">  Satın Alma Geçmişi </h3>
                            <br></br><br></br>
                            <h1></h1>
                            <div className="row" style={{  justifyContent: 'center', width: '105%' }}>
                                <button className="btn btn-primary" onClick={this.addBuyHistory}>Yeni Satın Alma Ekle</button>
                            </div>
                            <div style={{ marginRight: "25px" }}>
                                <br></br><br></br>
                                {product.buyHistory && product.buyHistory.length > 0 ? (
                                    <table className="table table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th>  Satıcı Firma </th>
                                                <th> Fatura Tarihi </th>
                                                <th>  Fatura No  </th>
                                                <th> Ürün Adedi </th>
                                                <th> Birim Fiyat (€) </th>
                                                <th> Birim Fiyat (₺) </th>
                                                <th>  Toplam Fiyat (€) </th>
                                                <th> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Eylemler &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {product.buyHistory.map((history) => (
                                                <tr key={history.id}>
                                                    <td> {history.saticiFirma} </td>
                                                    <td> {history.faturaTarihi} </td>
                                                    <td> {history.faturaNo} </td>
                                                    <td> {history.urunAdedi} </td>
                                                    <td> {history.birimFiyatEU} </td>
                                                    <td> {history.birimFiyatTR} </td>
                                                    <td> {history.toplamFiyat} </td>
                                                    <td style={{ textAlign: 'center'}}>
                                                        <button style={{ marginLeft: "10px" }} onClick={() => this.editBuyHistory(this.state.id, history.id)} className="btn btn-info"> Düzenle </button>
                                                        <button style={{ marginLeft: "10px" }} onClick={() => this.deleteBuyHistory(history.id)} className="btn btn-danger">Sil</button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    <div> Satın Alma Geçmişi Yok </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ViewProductComponent;
