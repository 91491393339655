import React, { Component } from 'react'
import ProductService from '../../services/ProductService'

class ListProductComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
                products: [],
                searchQuery: '',
                searchQuery2: '',
                searchQuery3: '',
                searchQuery4: ''

        }
        this.addProduct = this.addProduct.bind(this);
        this.editProduct = this.editProduct.bind(this);
        this.deleteProduct = this.deleteProduct.bind(this);

        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.searchProduct = this.searchProduct.bind(this);

        this.handleSearchChange2 = this.handleSearchChange2.bind(this);
        this.searchProduct2 = this.searchProduct2.bind(this);

        this.handleSearchChange3 = this.handleSearchChange3.bind(this);
        this.searchProduct3 = this.searchProduct3.bind(this);

        this.searchProduct4 = this.searchProduct4.bind(this);
    }

    handleSearchChange(event) {
        this.setState({ searchQuery: event.target.value });
    }

    searchProduct() {
        ProductService.getProducts(this.state.searchQuery).then((res) => {
            this.setState({ products: res.data });
        });
    }

    handleSearchChange2(event) {
        this.setState({ searchQuery2: event.target.value });
    }

    searchProduct2() {
        ProductService.getProducts2(this.state.searchQuery2).then((res) => {
            this.setState({ products: res.data });
        });
    }

    handleSearchChange3(event) {
        this.setState({ searchQuery3: event.target.value });
    }

    searchProduct3() {
        ProductService.getProducts3(this.state.searchQuery3).then((res) => {
            this.setState({ products: res.data });
        });
    }

    searchProduct4() {
        ProductService.getProducts4(this.state.searchQuery4).then((res) => {
            this.setState({ products: res.data });
        });
    }

    
    deleteProduct(id){
        ProductService.deleteProduct(id).then( res => {
            this.setState({products: this.state.products.filter(product => product.id !== id)});
        });
    }
    viewProduct(id){
        this.props.history.push(`/view-product/${id}`);
    }
    editProduct(id){
        this.props.history.push(`/add-product/${id}`);
    }

  componentDidMount(){
        ProductService.getProducts().then((res) => {
            if(res.data==null)
            {
                this.props.history.push('/add-product/_add');
            }
            this.setState({ products: res.data});
        });
    }

    addProduct(){
        this.props.history.push('/add-product/_add');
    }

    render() {
        return (
            <div>
                 <br></br><br></br>
                 <h2 className="text-center">Satın Alma Verileri</h2>
                 <br></br><br></br>
                 <div className="row">
                    <button className="btn btn-primary" onClick={this.addProduct}>Satın Alma Kaydı Ekle</button>
                    <th></th><th></th><th></th><th></th><th></th><th></th><th></th><th></th><th></th><th></th>
                    <button className="btn btn-primary" onClick={this.searchProduct4}>Aramayı Temizle</button>
                    <th></th><th></th><th></th><th></th><th></th><th></th><th></th><th></th><th></th><th></th><th></th><th></th>
                    <input style={{width:"295px"}} type="text" placeholder="Malzeme Ara" onChange={this.handleSearchChange}/>
                    <th></th><th></th><th></th><th></th>
                    <button className="btn btn-primary" onClick={this.searchProduct}>Ara</button>
                    <th></th><th></th><th></th><th></th><th></th><th></th><th></th><th></th><th></th><th></th><th></th><th></th>
                    <input style={{width:"295px"}} type="text" placeholder="Adet Ara" onChange={this.handleSearchChange3}/>
                    <th></th><th></th><th></th><th></th>
                    <button className="btn btn-primary" onClick={this.searchProduct3}>Ara</button>
                 </div>
                 <br></br><br></br>
                 <div className = "row ">
                        <table className = "table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Malzeme Adı&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                    <th> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Adet&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                    <th> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Eylemler&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.products.map(
                                        product => 
                                        <tr key = {product.id}>
                                             <td> {product.malzemeAdi} </td>   
                                             <td > {product.adetNo}</td>
                                             <td style={{textAlign: 'center'}}>
                                                 <button onClick={ () => this.editProduct(product.id)} className="btn btn-info">Güncelle </button>
                                                 <button style={{marginLeft: "10px"}} onClick={ () => this.viewProduct(product.id)} className="btn btn-info">Geçmiş Alımlar </button>
                                                 <button style={{marginLeft: "10px"}} onClick={ () => this.deleteProduct(product.id)} className="btn btn-danger">Sil </button>

                                             </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>

                 </div>

            </div>
        )
    }
}

export default ListProductComponent
