import React, { Component } from 'react'
import UserService from '../../services/UserService'
import { AuthContext } from '../../context/AuthContext';

class ListUserComponent extends Component {
    static contextType = AuthContext;
    constructor(props) {
        super(props)

        this.state = {
                users: [],
                searchQuery: '',
                searchQuery2: '',
                searchQuery3: '',
                searchQuery4: '',
                searchQuery5: '',
                searchQuery6: '',
                searchQuery7: '',
                searchQuery8: '',
                searchQuery9: '',
                searchQuery10: '',
                searchQuery11: '',
                searchQuery12: '',
        }
        this.addUser = this.addUser.bind(this);
        this.editUser = this.editUser.bind(this);
        this.deleteUser = this.deleteUser.bind(this);

        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.searchUser = this.searchUser.bind(this);

        this.handleSearchChange2 = this.handleSearchChange2.bind(this);
        this.searchUser2 = this.searchUser2.bind(this);

        this.handleSearchChange3 = this.handleSearchChange3.bind(this);
        this.searchUser3 = this.searchUser3.bind(this);

        this.handleSearchChange4 = this.handleSearchChange4.bind(this);
        this.searchUser4 = this.searchUser4.bind(this);

        this.handleSearchChange5 = this.handleSearchChange5.bind(this);
        this.searchUser5 = this.searchUser5.bind(this);

        this.handleSearchChange6 = this.handleSearchChange6.bind(this);
        this.searchUser6 = this.searchUser6.bind(this);

        this.handleSearchChange7 = this.handleSearchChange7.bind(this);
        this.searchUser7 = this.searchUser7.bind(this);

        this.handleSearchChange8 = this.handleSearchChange8.bind(this);
        this.searchUser8 = this.searchUser8.bind(this);

        this.handleSearchChange10 = this.handleSearchChange10.bind(this);
        this.searchUser10 = this.searchUser10.bind(this);

        this.handleSearchChange11 = this.handleSearchChange11.bind(this);
        this.searchUser11 = this.searchUser11.bind(this);

        this.handleSearchChange12 = this.handleSearchChange12.bind(this);
        this.searchUser12 = this.searchUser12.bind(this);

        this.searchUser9 = this.searchUser9.bind(this);
    }

    handleSearchChange(event) {
        this.setState({ searchQuery: event.target.value });
    }

    searchUser() {
        UserService.getUsers(this.state.searchQuery).then((res) => {
            this.setState({ users: res.data });
        });
    }
   
    handleSearchChange2(event) {
        this.setState({ searchQuery2: event.target.value });
    }

    searchUser2() {
        UserService.getUsers2(this.state.searchQuery2).then((res) => {
            this.setState({ users: res.data });
        });
    }

    handleSearchChange3(event) {
        this.setState({ searchQuery3: event.target.value });
    }

    searchUser3() {
        UserService.getUsers3(this.state.searchQuery3).then((res) => {
            this.setState({ users: res.data });
        });
    }

    handleSearchChange4(event) {
        this.setState({ searchQuery4: event.target.value });
    }

    searchUser4() {
        UserService.getUsers4(this.state.searchQuery4).then((res) => {
            this.setState({ users: res.data });
        });
    }
   
    handleSearchChange5(event) {
        this.setState({ searchQuery5: event.target.value });
    }

    searchUser5() {
        UserService.getUsers5(this.state.searchQuery5).then((res) => {
            this.setState({ users: res.data });
        });
    }

    handleSearchChange6(event) {
        this.setState({ searchQuery6: event.target.value });
    }

    searchUser6() {
        UserService.getUsers6(this.state.searchQuery6).then((res) => {
            this.setState({ users: res.data });
        });
    }

    handleSearchChange7(event) {
        this.setState({ searchQuery7: event.target.value });
    }

    searchUser7() {
        UserService.getUsers7(this.state.searchQuery7).then((res) => {
            this.setState({ users: res.data });
        });
    }
   
    handleSearchChange8(event) {
        this.setState({ searchQuery8: event.target.value });
    }

    searchUser8() {
        UserService.getUsers8(this.state.searchQuery8).then((res) => {
            this.setState({ users: res.data });
        });
    }

    handleSearchChange10(event) {
        this.setState({ searchQuery10: event.target.value });
    }

    searchUser10() {
        UserService.getUsers10(this.state.searchQuery10).then((res) => {
            this.setState({ users: res.data });
        });
    }

    handleSearchChange11(event) {
        this.setState({ searchQuery11: event.target.value });
    }

    searchUser11() {
        UserService.getUsers11(this.state.searchQuery11).then((res) => {
            this.setState({ users: res.data });
        });
    }

    searchUser9() {
        UserService.getUsers9(this.state.searchQuery9).then((res) => {
            this.setState({ users: res.data });
        });
    }

    handleSearchChange12(event) {
        this.setState({ searchQuery12: event.target.value });
    }

    searchUser12() {
        UserService.getUsers12(this.state.searchQuery12).then((res) => {
            this.setState({ users: res.data });
        });
    }

    deleteUser(id){
        UserService.deleteUser(id).then( res => {
            this.setState({users: this.state.users.filter(user => user.id !== id)});
        });
    }
    viewUser(id){
        this.props.history.push(`/view-user/${id}`);
    }
    editUser(id){
        this.props.history.push(`/add-user/${id}`);
    }

  componentDidMount(){
        UserService.getUsers().then((res) => {
            if(res.data==null)
            {
                this.props.history.push('/add-user/_add');
            }
            this.setState({ users: res.data});
        });
    }

    addUser(){
        this.props.history.push('/add-user/_add');
    }

    render() {
        return (
            <div>
                 <br></br>
                 <h2 className="text-center">Motor Onarım Satış Verileri </h2>
                 <br></br><br></br>
                 <div className="row" style={{ display: 'flex', justifyContent: 'center', width: '105%' }}>
                    <button className="btn btn-primary" onClick={this.addUser}> Satış Ekle</button>
                    <th></th><th></th><th></th><th></th><th></th><th></th><th></th><th></th>
                    <input type="text" placeholder="Barkod No Ara" onChange={this.handleSearchChange7}/>
                    <th></th><th></th>
                    <button className="btn btn-primary" onClick={this.searchUser7}>Ara</button>
                    <th></th><th></th><th></th><th></th><th></th><th></th><th></th><th></th>
                    <input type="text" placeholder="Firma Adı Ara" onChange={this.handleSearchChange}/>
                    <th></th><th></th>
                    <button className="btn btn-primary" onClick={this.searchUser}>Ara</button>
                    <th></th><th></th><th></th><th></th><th></th><th></th><th></th><th></th>
                    <input type="text" placeholder="Giriş Tarihi Ara" onChange={this.handleSearchChange2}/>
                    <th></th><th></th>
                    <button className="btn btn-primary" onClick={this.searchUser2}>Ara</button>
                    <th></th><th></th><th></th><th></th><th></th><th></th><th></th><th></th>
                    <input type="text" placeholder="Çıkış Tarihi Ara" onChange={this.handleSearchChange3}/>
                    <th></th><th></th>
                    <button className="btn btn-primary" onClick={this.searchUser3}>Ara</button>
                    <th></th><th></th><th></th><th></th><th></th><th></th><th></th><th></th>
                </div>
                <h1></h1>
                <div className="row" style={{ display: 'flex', justifyContent: 'center', width: '105%' }}>
                    <button className="btn btn-primary" onClick={this.searchUser9}> Temizle</button>
                    <th></th><th></th><th></th><th></th><th></th><th></th><th></th><th></th><th></th><th></th><th></th><th></th><th></th><th></th><th></th>
                    <input type="text" placeholder="Motor Markası Ara" onChange={this.handleSearchChange11}/>
                    <th></th><th></th>
                    <button className="btn btn-primary" onClick={this.searchUser11}>Ara</button>
                    <th></th><th></th><th></th><th></th><th></th><th></th><th></th><th></th>
                    <input type="text" placeholder="Motor Gücü Ara" onChange={this.handleSearchChange4}/>
                    <th></th><th></th>
                    <button className="btn btn-primary" onClick={this.searchUser4}>Ara</button>
                    <th></th><th></th><th></th><th></th><th></th><th></th><th></th><th></th>
                    <input type="text" placeholder="Rulman Numarası Ara" onChange={this.handleSearchChange10}/>
                    <th></th><th></th>
                    <button className="btn btn-primary" onClick={this.searchUser10}>Ara</button>
                    <th></th><th></th><th></th><th></th><th></th><th></th><th></th><th></th>
                    <input type="text" placeholder="Yapılan İşlem Ara" onChange={this.handleSearchChange6}/>
                    <th></th><th></th>
                    <button className="btn btn-primary" onClick={this.searchUser6}>Ara</button>
                    <th></th><th></th><th></th><th></th><th></th><th></th><th></th><th></th>
                    </div>
                    <h1></h1><h1></h1>
                    <div className="row">
                        <th></th><th></th>
                        <h4> Fatura: </h4>
                        <th></th><th></th><th></th><th></th><th></th><th></th><th></th><th></th><th></th><th></th><th></th><th></th>
                        <th></th><th></th><th></th><th></th><th></th>
                        <button className="btn btn-primary" style={{ fontSize: '14px', padding: '5px 10px' }} onClick={async () => {await this.handleSearchChange8({ target: { value: 'kesildi' } }); this.searchUser8(); }}>Kesildi</button>
                        <th></th><th></th>
                        <button className="btn btn-primary" style={{ fontSize: '14px', padding: '5px 10px' }} onClick={async () => {await this.handleSearchChange8({ target: { value: 'kesilmedi' } }); this.searchUser8(); }}>Kesilmedi</button>
                        <th></th><th></th>
                        <button className="btn btn-primary" style={{ fontSize: '14px', padding: '5px 10px' }} onClick={async () => {await this.handleSearchChange8({ target: { value: 'kesilebilir' } }); this.searchUser8(); }}>Kesilebilir</button>
                    </div>
                 <br></br><br></br>
                 <div className = "row" style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                        <table className = "table table-striped table-bordered" >
                            <thead>
                                <tr>
                                    <th> Barkod Numarası</th>
                                    <th> Firma Adı</th>
                                    <th> Giriş Tarihi</th>
                                    <th> Çıkış Tarihi</th>
                                    <th> Motor Markası</th>
                                    <th> Motor Gücü</th>
                                    <th> Motor Devri</th>
                                    <th> Rulman Numarası</th>
                                    <th> Yapılan İşlemler</th>
                                    <th> Fatura Durumu</th>
                                    <th> Notlar</th>
                                    <th> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Eylemler&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.users.map(
                                        user => 
                                        <tr key = {user.id}>
                                             <td style={{ wordWrap: 'break-word', overflowWrap: 'break-word', maxWidth: '100px' }}> {user.barkodNo}</td>
                                             <td style={{ wordWrap: 'break-word', overflowWrap: 'break-word', maxWidth: '120px' }}> {user.firmaAdi} </td>   
                                             <td style={{ wordWrap: 'break-word', overflowWrap: 'break-word', maxWidth: '120px' }}>{user.girisTarihi}</td>
                                             <td style={{ wordWrap: 'break-word', overflowWrap: 'break-word', maxWidth: '120px' }}> {user.cikisTarihi}</td>
                                             <td style={{ wordWrap: 'break-word', overflowWrap: 'break-word', maxWidth: '100px' }}> {user.motorMarkasi}</td>
                                             <td style={{ wordWrap: 'break-word', overflowWrap: 'break-word', maxWidth: '100px' }}> {user.motorGucu}</td>
                                             <td style={{ wordWrap: 'break-word', overflowWrap: 'break-word', maxWidth: '100px' }}> {user.motorDevri}</td>
                                             <td style={{ wordWrap: 'break-word', overflowWrap: 'break-word', maxWidth: '100px' }}> {user.rulmanNo}</td>
                                             <td style={{ wordWrap: 'break-word', overflowWrap: 'break-word', maxWidth: '200px' }}>{user.yapilanIslem}</td>
                                             <td style={{ wordWrap: 'break-word', overflowWrap: 'break-word', maxWidth: '100px' }}> {user.faturaDurumu}</td>
                                             <td style={{ wordWrap: 'break-word', overflowWrap: 'break-word', maxWidth: '100px' }}> {user.notKismi}</td>
                                             <td>
                                                 <button onClick={ () => this.editUser(user.id)} className="btn btn-info">Güncelle </button>
                                                 <button style={{marginLeft: "10px"}} onClick={ () => this.viewUser(user.id)} className="btn btn-info">Detay </button>
                                                 <button style={{marginLeft: "10px"}} onClick={ () => this.deleteUser(user.id)} className="btn btn-danger">Sil </button>
                                             </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>

                 </div>

            </div>
        )
    }
}

export default ListUserComponent
