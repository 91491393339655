import React, { Component } from 'react';
import ProductService from '../../services/ProductService';
import axios from 'axios';
import InputMask from 'react-input-mask'; 

class CreateBuyHistoryComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            productId: this.props.match.params.id,
            saticiFirma: '',
            faturaTarihi: '',
            faturaNo: '',
            urunAdedi: '',
            birimFiyatEU: '',
            birimFiyatTR: '',
            toplamFiyat: '',
            exchangeRate: null,
            exchangeRateError: ''
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.saveBuyHistory = this.saveBuyHistory.bind(this);
        this.getExchangeRate = this.getExchangeRate.bind(this);
    }

    handleInputChange(event) {
        const { name, value } = event.target;
        this.setState({ [name]: value }, () => {
            if (name === 'birimFiyatTR' || name === 'exchangeRate') {
                this.updateBirimFiyatEU();
            }
            if (name === 'urunAdedi' || name === 'birimFiyatEU') {
                this.updateToplamFiyat();
            }
        });
    }

    updateBirimFiyatEU() {
        const { birimFiyatTR, exchangeRate } = this.state;
        const birimFiyatTRFloat = parseFloat(birimFiyatTR);
        const exchangeRateFloat = parseFloat(exchangeRate);

        if (!isNaN(birimFiyatTRFloat) && !isNaN(exchangeRateFloat)) {
            const birimFiyatEU = (birimFiyatTRFloat / exchangeRateFloat).toFixed(2);
            this.setState({ birimFiyatEU });
        } else {
            this.setState({ birimFiyatEU: '' });
        }
    }

    updateToplamFiyat() {
        const { urunAdedi, birimFiyatEU } = this.state;
        const urunAdediFloat = parseFloat(urunAdedi);
        const birimFiyatEUFloat = parseFloat(birimFiyatEU);

        if (!isNaN(urunAdediFloat) && !isNaN(birimFiyatEUFloat)) {
            const toplamFiyat = urunAdediFloat * birimFiyatEUFloat;
            this.setState({ toplamFiyat: toplamFiyat.toFixed(2) });
        } else {
            this.setState({ toplamFiyat: '' });
        }
    }

    getExchangeRate() {
        const { faturaTarihi } = this.state;

        const dateParts = faturaTarihi.split('.');
        if (dateParts.length !== 3 || dateParts[2].length !== 4) {
            this.setState({ exchangeRateError: 'Lütfen geçerli bir tarih giriniz (Örnek: 30.12.2023).' });
            return;
        }
        //https://manage.exchangeratesapi.io/dashboard?logged_in=1
        const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
        axios.get(`https://api.exchangeratesapi.io/v1/${formattedDate}?access_key=ae1831a74500e1bd49a41dbc5af4b349&symbols=TRY`)
            .then(response => {
                const rate = response.data.rates.TRY;
                this.setState({ exchangeRate: rate, exchangeRateError: '' }, () => {
                    this.updateBirimFiyatEU();
                });
            })
            .catch(error => {
                console.error('Error fetching exchange rate:', error);
                this.setState({ exchangeRateError: 'Geçerli bir tarih giriniz.' });
            });
    }

    saveBuyHistory(e) {
        e.preventDefault();
        const { productId, saticiFirma, faturaTarihi, faturaNo, urunAdedi, birimFiyatEU, birimFiyatTR, toplamFiyat } = this.state;
        const buyHistory = { saticiFirma, faturaTarihi, faturaNo, urunAdedi, birimFiyatEU, birimFiyatTR, toplamFiyat };

        ProductService.addBuyHistory(productId, buyHistory).then(res => {
            this.props.history.push(`/view-product/${productId}`);
        });
    }

    cancel() {
        this.props.history.push(`/view-product/${this.state.productId}`);
    }

    render() {
        return (
            <div  style={{ marginBottom: "50px" }}>
                <br />
                <div className="container">
                    <div className="row">
                        <div className="card col-md-6 offset-md-3 offset-md-3">
                            <h3 className="text-center">Yeni Satın Alma Kaydı Ekle</h3>
                            <div className="card-body">
                                <form>
                                    <div className="form-group">
                                        <label> Satıcı Firma: </label>
                                        <input
                                            type="text"
                                            name="saticiFirma"
                                            className="form-control"
                                            value={this.state.saticiFirma}
                                            onChange={this.handleInputChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label> Fatura No: </label>
                                        <input
                                            type="text"
                                            name="faturaNo"
                                            className="form-control"
                                            value={this.state.faturaNo}
                                            onChange={this.handleInputChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label> Birim Fiyat (₺): </label>
                                        <input
                                            type="text"
                                            name="birimFiyatTR"
                                            className="form-control"
                                            value={this.state.birimFiyatTR}
                                            onChange={this.handleInputChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label> Fatura Tarihi: </label>
                                        <InputMask
                                            mask="99.99.9999"
                                            placeholder="Fatura Tarihi (Örnek: 30.12.2023)"
                                            name="faturaTarihi"
                                            className="form-control"
                                            value={this.state.faturaTarihi}
                                            onChange={this.handleInputChange}
                                        />
                                        <button type="button" className="btn btn-primary mt-2" onClick={this.getExchangeRate}>Tarihsel Kur Hesapla</button>
                                        {this.state.exchangeRate && (
                                            <div className="mt-2">
                                                <strong>EUR/TRY Kur:</strong> {this.state.exchangeRate} ₺
                                            </div>
                                        )}
                                        {this.state.exchangeRateError && (
                                            <div className="mt-2 text-danger">
                                                {this.state.exchangeRateError}
                                            </div>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <label> Ürün Adedi: </label>
                                        <input
                                            type="text"
                                            name="urunAdedi"
                                            className="form-control"
                                            value={this.state.urunAdedi}
                                            onChange={this.handleInputChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label> Birim Fiyat (€): </label>
                                        <input
                                            type="text"
                                            name="birimFiyatEU"
                                            className="form-control"
                                            value={this.state.birimFiyatEU}
                                            readOnly
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label> Toplam Fiyat (€): </label>
                                        <input
                                            type="text"
                                            name="toplamFiyat"
                                            className="form-control"
                                            value={this.state.toplamFiyat}
                                            readOnly
                                        />
                                    </div>
                                    <div style={{ textAlign: "center" }}>
                                        <button className="btn btn-success" onClick={this.saveBuyHistory}>Kaydet</button>
                                        <button className="btn btn-danger" onClick={this.cancel.bind(this)} style={{ marginLeft: "10px" }}>İptal</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CreateBuyHistoryComponent;
