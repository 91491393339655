import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import InvoiceService from "../../services/InvoiceService";

const ViewInvoiceComponent = () => {
  const { id } = useParams();
  const [invoice, setInvoice] = useState(null);

  useEffect(() => {
    InvoiceService.getInvoiceById(id).then((response) => {
      setInvoice(response.data);
    }).catch((error) => {
      console.error("There was an error fetching the invoice!", error);
    });
  }, [id]);

  if (!invoice) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h2>Invoice Details</h2>
      <div>No: {invoice.no}</div>
      <div>Firma: {invoice.firma}</div>
      <div>Birim: {invoice.birim}</div>
      <div>İlgili: {invoice.ilgili}</div>
      <div>Konu: {invoice.konu}</div>
      <div>Tarih: {invoice.tarih}</div>
      <h3>Items</h3>
      {invoice.items.map((item, index) => (
        <div key={index}>
          <div>Item Number: {item.itemNumber}</div>
          <div>Name: {item.name}</div>
          <div>Quantity: {item.quantity}</div>
          <div>Price: {item.price}</div>
          <div>Unit: {item.unit}</div>
        </div>
      ))}
    </div>
  );
};

export default ViewInvoiceComponent;
