import React, { Component } from 'react'
import ProductService from '../../services/ProductService';

class CreateProductComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            // step 2
            id: this.props.match.params.id,
            malzemeAdi: '',
            seriNumara: '',
            adetNo: ''
        }
        this.changeMalzemeAdiHandler = this.changeMalzemeAdiHandler.bind(this);
        this.changeSeriNumaraHandler = this.changeSeriNumaraHandler.bind(this);
        this.saveOrUpdateProduct = this.saveOrUpdateProduct.bind(this);
    }

    // step 3
    componentDidMount() {

        // step 4
        if (this.state.id === '_add') {
            return
        } else {
            ProductService.getProductById(this.state.id).then((res) => {
                let product = res.data;
                this.setState({
                    malzemeAdi: product.malzemeAdi,
                    seriNumara: product.seriNumara,
                    adetNo: product.adetNo
                });
            });
        }
    }
    saveOrUpdateProduct = (e) => {
        e.preventDefault();
        let product = { malzemeAdi: this.state.malzemeAdi, seriNumara: this.state.seriNumara, adetNo: this.state.adetNo };
        console.log('product => ' + JSON.stringify(product));

        // step 5
        if (this.state.id === '_add') {
            ProductService.createProduct(product).then(res => {
                this.props.history.push('/products');
            });
        } else {
            ProductService.updateProduct(product, this.state.id).then(res => {
                this.props.history.push('/products');
            });
        }
    }

    changeMalzemeAdiHandler = (event) => {
        this.setState({ malzemeAdi: event.target.value });
    }

    changeSeriNumaraHandler = (event) => {
        this.setState({ seriNumara: event.target.value });
    }

    changeAdetNoHandler = (event) => {
        this.setState({ adetNo: event.target.value });
    }

    cancel() {
        this.props.history.push('/products');
    }

    getTitle() {
        if (this.state.id === '_add') {
            return <h3 className="text-center">Satın Alma Kaydı Ekle</h3>
        } else {
            return <h3 className="text-center">Satın Alma Kaydı Güncelle</h3>
        }
    }
    render() {
        return (
            <div style={{ marginBottom: "50px" }}>
                <br></br>
                <div className="container">
                    <div className="row">
                        <div className="card col-md-6 offset-md-3 offset-md-3"><br />
                            {
                                this.getTitle()
                            }
                            <div className="card-body">
                                <form>
                                    <div className="form-group">
                                        <label> Malzeme Adı: </label>
                                        <input placeholder="" name="malzemeAdi" className="form-control"
                                            value={this.state.malzemeAdi} onChange={this.changeMalzemeAdiHandler} />
                                    </div>
                                    <div className="form-group">
                                        <label> Adet: </label>
                                        <input placeholder="" name="adetNo" className="form-control"
                                            value={this.state.adetNo} onChange={this.changeAdetNoHandler} />
                                    </div>
                                    <div style={{ textAlign: "center" }}>
                                        <button className="btn btn-success" onClick={this.saveOrUpdateProduct}>Kaydet</button>
                                        <button className="btn btn-danger" onClick={this.cancel.bind(this)} style={{ marginLeft: "10px" }}>İptal</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default CreateProductComponent
