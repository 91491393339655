import React from 'react';

import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'

import ListProductComponent from '../products/ListProductComponent';
import CreateProductComponent from '../products/CreateProductComponent';
import ViewProductComponent from '../products/ViewProductComponent';


const Stok = () => {
    return (
        <div className="container">
        <Switch>
              <Route path = "/stok" exact component = {ListProductComponent}></Route>
              <Route path = "/products" component = {ListProductComponent}></Route>
              <Route path = "/add-product/:id" component = {CreateProductComponent}></Route>
              <Route path = "/view-product/:id" component = {ViewProductComponent}></Route>
        </Switch>
    </div>
    );
};

export default Stok;